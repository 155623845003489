<template>
    <b-modal title="Uporabnik" v-model="modalActive" hide-footer no-close-on-backdrop>
        <validation-observer ref="validation">
            <b-form-group label="Ime">
                <validation-provider #default="{ errors }" name="ime" rules="required">
                    <b-form-input v-model="userObject.name"/>
                    <small class="text-danger">{{errors[0]}}</small>
                </validation-provider>
            </b-form-group>
            <b-form-group label="Email">
                <validation-provider #default="{ errors }" name="email" rules="required|email">
                    <b-form-input v-model="userObject.email"/>
                    <small class="text-danger">{{errors[0]}}</small>
                </validation-provider>
            </b-form-group>
            <template v-if="editing">
                <b-form-group label="Geslo">
                    <validation-provider #default="{ errors }" name="geslo" vid="password_edit">
                        <b-form-input v-model="userObject.password" type="password" name="password_edit" placeholder="Prazno polje pomeni nespremenjeno geslo"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Ponovi geslo">
                    <validation-provider #default="{ errors }" name="ponovi geslo" :rules="{required: userObject.password.length > 0, confirmed: 'password_edit'}">
                        <b-form-input v-model="repeatPassword" name="repeat_password" type="password" data-vv-as="password_edit"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </template>
            <template v-else>
                <b-form-group label="Geslo">
                    <validation-provider #default="{ errors }" name="geslo" rules="required" vid="password">
                        <b-form-input v-model="userObject.password" type="password" name="password"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Ponovi geslo">
                    <validation-provider #default="{ errors }" name="ponovi geslo" rules="required|confirmed:password">
                        <b-form-input v-model="repeatPassword" name="repeat_password" type="password" data-vv-as="password"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </template>
        </validation-observer>

        <div class="d-flex w-full justify-content-end">
            <b-button variant="primary" @click="confirm" :disabled="loading">
                {{buttonTitle}} <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"/>
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import {BButton, BFormGroup, BModal, BFormInput, BSpinner} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {email, confirmed} from '@core/utils/validations/validations'

    export default {
        components: {
            BFormInput,
            BButton,
            ValidationProvider,
            ValidationObserver,
            BFormGroup,
            BModal,
            BSpinner
        },
        props: {
            resellerId: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                loading: false,
                userObject: {
                    id: '',
                    name: '',
                    email: '',
                    password: '',
                    reseller_id: ''
                },
                repeatPassword: '',
                modalActive: false,
                editing: false,
                email,
                confirmed
            }
        },
        methods: {
            open(data = null) {
                this.loading = false
                if (data !== null) {
                    this.userObject = JSON.parse(JSON.stringify(data))
                    this.editing = true
                } else {
                    this.editing = false
                    this.makeEmpty()
                }
                this.modalActive = true
            },
            confirm() {
                if (this.editing) this.editUser()
                else this.addUser()
            },
            async editUser() {
                const valid = await this.$refs.validation.validate()
                if (!valid) return

                try {
                    this.loading = true
                    this.userObject.reseller_id = this.resellerId
                    await this.$http.put(`/api/admin/v1/resellers/${this.resellerId}/users/${this.userObject.id}`, this.userObject)
                    this.$printSuccess('Uporabnik uspešno posodobljen')
                    this.modalActive = false
                    this.makeEmpty()
                    this.$emit('added')
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                        if (error.response.data.error === 'user already exists on reseller') this.$printError('Uporabnik s tem emailom že obstaja')
                        else this.$printError('Prišlo je do napake pri urejanju')
                    } else this.$printError('Prišlo je do napake pri urejanju')
                } finally {
                    this.loading = false
                }
            },
            async addUser() {
                const valid = await this.$refs.validation.validate()
                if (!valid) return

                try {
                    this.loading = true
                    this.userObject.reseller_id = this.resellerId
                    await this.$http.post(`/api/admin/v1/resellers/${this.resellerId}/users`, this.userObject)
                    this.$printSuccess('Uporabnik uspešno dodan')
                    this.modalActive = false
                    this.makeEmpty()
                    this.$emit('added')
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                        if (error.response.data.error === 'user already exists on reseller') this.$printError('Uporabnik s tem emailom že obstaja')
                        else this.$printError('Prišlo je do napake pri dodajanju')
                    } else this.$printError('Prišlo je do napake pri dodajanju')
                } finally {
                    this.loading = false
                }
            },
            makeEmpty() {
                this.userObject =  {
                    id: '',
                    name: '',
                    email: '',
                    password: '',
                    reseller_id: ''
                }
                this.repeatPassword = ''
            }
        },
        computed: {
            buttonTitle() {
                return this.editing ? 'Uredi' : 'Dodaj'
            }
        }
    }
</script>
