<template>
    <b-overlay :show="showLoader">
        <b-card title="Podatki prodajalca" v-if="reseller">
            <validation-observer ref="validationReseller">
                <div class="row">
                    <b-form-group class="col-12 col-md-6" label="Naziv">
                        <validation-provider #default="{ errors }" name="naziv" rules="required">
                            <b-form-input v-model="reseller.name"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-12 col-md-6" label="Alias">
                        <validation-provider #default="{ errors }" name="alias" rules="required">
                            <b-form-input v-model="reseller.alias"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                </div>
            </validation-observer>

            <div class="d-flex w-full justify-content-end">
                <b-button variant="primary" @click="editReseller" :disabled="loading">
                    Potrdi spremembe <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"/>
                </b-button>
            </div>
        </b-card>

        <b-card title="Uporabniki">
            <div class="d-flex w-full justify-content-end">
                <b-button variant="primary" @click="openAddUserModal">
                    Dodaj uporabnika
                </b-button>
            </div>

            <basic-table :columns="columns" :data="users" v-slot="props" :enableRowMarking="true">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="warning" class="mr-1" @click="openEditUserModal(props.row)"><feather-icon icon="EditIcon"/></b-button>
                    <b-button variant="danger" class="mr-0" @click="deleteUser(props.row.id, props.row.name)"><feather-icon icon="TrashIcon"/></b-button>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>

        <UserModal ref="userModal" @added="loadData" :reseller-id="resellerId"></UserModal>
    </b-overlay>
</template>

<script>
    import {
        BFormGroup,
        BFormInput,
        BCard, BButton, BSpinner, BOverlay
    } from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import BasicTable from '@/views/components/BasicTable.vue'
    import UserModal from '@/views/Reseller/UserModal.vue'

    export default {
        components: {
            BOverlay,
            BSpinner,
            UserModal,
            BButton,
            BasicTable,
            BFormGroup,
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BCard
        },
        data() {
            return {
                showLoader: false,
                loading: false,
                resellerId: '',
                reseller: undefined,
                users: [],
                columns: [
                    {
                        label: 'Ime',
                        field: 'name',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Možnosti',
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ]

            }
        },
        methods: {
            openEditUserModal(user) {
                this.$refs.userModal.open(user)
            },
            openAddUserModal() {
                this.$refs.userModal.open(null)
            },
            async editReseller() {
                const valid = await this.$refs.validationReseller.validate()
                if (!valid) return

                try {
                    this.loading = true
                    await this.$http.put(`/api/admin/v1/resellers/${this.reseller.id}`, this.reseller)
                    this.$printSuccess('Urejanje je bilo uspešno!')
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                        if (error.response.data.error === 'reseller with name or alias exists') this.$printError('Prodajalec s tem nazivom ali aliasom že obstaja')
                        else if (error.response.data.error === 'invalid data') this.$printError('Podatki niso veljavni')
                        else this.$printError('Prišlo je do napake')
                    } else this.$printError('Prišlo je do napake')
                } finally {
                    this.loading = false
                }
            },
            async deleteUser(id, name) {
                const confirmDelete = await this.$confirmDialog(`Ali res želite izbrisati uporabnika: ${name}?`)
                if (!confirmDelete) return

                try {
                    this.showLoader = true
                    await this.$http.delete(`/api/admin/v1/resellers/${this.resellerId}/users/${id}`)
                    this.$printSuccess('Uporabnik uspešno izbrisan')
                    await this.loadData()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri brisanju')
                } finally {
                    this.showLoader = false
                }
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const resellerResponse = this.$http.get(`/api/admin/v1/resellers/${this.resellerId}`)
                    const userResponse = this.$http.get(`/api/admin/v1/resellers/${this.resellerId}/users`)
                  
                    const responses = await Promise.all([resellerResponse, userResponse])
                    this.reseller = responses[0].data
                    this.users = responses[1].data ?? []
                } catch (err) {
                    this.$printError(err)
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            this.resellerId = this.$route.params.reseller_id
            await this.loadData()
        }
    }
</script>