var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Uporabnik","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}},[_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":"Ime"}},[_c('validation-provider',{attrs:{"name":"ime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.userObject.name),callback:function ($$v) {_vm.$set(_vm.userObject, "name", $$v)},expression:"userObject.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.userObject.email),callback:function ($$v) {_vm.$set(_vm.userObject, "email", $$v)},expression:"userObject.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.editing)?[_c('b-form-group',{attrs:{"label":"Geslo"}},[_c('validation-provider',{attrs:{"name":"geslo","vid":"password_edit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","name":"password_edit","placeholder":"Prazno polje pomeni nespremenjeno geslo"},model:{value:(_vm.userObject.password),callback:function ($$v) {_vm.$set(_vm.userObject, "password", $$v)},expression:"userObject.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1725253886)})],1),_c('b-form-group',{attrs:{"label":"Ponovi geslo"}},[_c('validation-provider',{attrs:{"name":"ponovi geslo","rules":{required: _vm.userObject.password.length > 0, confirmed: 'password_edit'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"repeat_password","type":"password","data-vv-as":"password_edit"},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3411403344)})],1)]:[_c('b-form-group',{attrs:{"label":"Geslo"}},[_c('validation-provider',{attrs:{"name":"geslo","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","name":"password"},model:{value:(_vm.userObject.password),callback:function ($$v) {_vm.$set(_vm.userObject, "password", $$v)},expression:"userObject.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Ponovi geslo"}},[_c('validation-provider',{attrs:{"name":"ponovi geslo","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"repeat_password","type":"password","data-vv-as":"password"},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]],2),_c('div',{staticClass:"d-flex w-full justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.buttonTitle)+" "),(_vm.loading)?_c('b-spinner',{staticClass:"ml-1",staticStyle:{"height":"15px","width":"15px"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }